import React, { useState} from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useNavigate } from "@reach/router"
import "../styles/styles.scss"

import DOMPurify from 'isomorphic-dompurify'

import { slugify } from "../util/util"
import { randomBetween } from "../util/util"

import favicon from "../images/favicon.svg";
import Logo from "../images/urbanite-logo.png"

import CircuitIn from "../images/inline/circuit-in.svg"
import ChipIO from "../images/inline/chip-io.svg"

import CircuitCornerTL from "../images/inline/circuit-corner-t-l.svg"
import CircuitCornerTR from "../images/inline/circuit-corner-t-r.svg"
import CircuitCornerBL from "../images/inline/circuit-corner-b-l.svg"
import CircuitCornerBR from "../images/inline/circuit-corner-b-r.svg"

import CircuitCenterT from "../images/inline/circuit-center-t.svg"
import CircuitCenterB from "../images/inline/circuit-center-b.svg"

import CircuitHorizontal from "../images/inline/circuit-horizontal.svg"
import CircuitVertical from "../images/inline/circuit-vertical.svg"

import Bus from "../images/inline/bus.svg"
import Bike from "../images/inline/bike.svg"

import Europe from "../images/inline/europe.svg"
import textOnlyPdf from "../files/urbanite-d2.1-text-only.pdf"


DOMPurify.addHook('afterSanitizeAttributes', function(node) {
  if ('target' in node) {
      node.setAttribute('target','_blank');
      node.setAttribute('rel', 'noopener noreferrer');
  }
});
DOMPurify.setConfig({FORBID_TAGS: ['span', 'u'], ADD_TAGS: ['iframe']})

const makeCaseStudyModalContent = (caseStudy)=> {
  return {
    title: caseStudy.title,
    body: <>
      { caseStudy.credits && <div className="credits" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(caseStudy.credits) }} /> }
      { caseStudy.image && <img className="case-image" 
      src={caseStudy.image.childImageSharp.gatsbyImageData.images.fallback.src} 
      srcSet={caseStudy.image.childImageSharp.gatsbyImageData.images.sources.srcSet}
      width={caseStudy.image.childImageSharp.gatsbyImageData.width}
      height={caseStudy.image.childImageSharp.gatsbyImageData.height}
      /> }
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(caseStudy.body) }} />
      { caseStudy.references && <div className="references"><h4>References</h4><div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(caseStudy.references) }} /></div> }
    </>
  }
}

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query Query {
      strapiHeader {
        title
        subtitle
        links {
          id
          title
          link
        }
      }
      strapiSiteHeader {
        title
        introduction
      }
      strapiAbout {
        title
        body
      }
      strapiConclusions {
        title
        body
      }
      strapiReferences {
        title
        body
      }
      allStrapiIntroduction(sort: {fields: order}) {
        nodes {
          order
          title
          body
          references
        }
      }
      allStrapiDisruption(sort: {fields: order}) {
        nodes {
          order
          theme
          title
          description
          references
        }
      }
      allStrapiCaseStudy(sort: {fields: order}) {
        nodes {
          title
          credits
          body
          references
          order
          disruption {
            order
          }
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  const caseStudies = data.allStrapiCaseStudy.nodes
  const disruptions = data.allStrapiDisruption.nodes.map(disruption => {
    disruption.caseStudies = caseStudies.filter(caseStudy => caseStudy.disruption.order === disruption.order)
    return disruption
  })

  const initialOpenState = disruptions.map(() => [false,false])
  const [openState, setOpenState] = useState(initialOpenState);

  const navigate = useNavigate()

  const initialCaseStudy = caseStudies.find(caseStudy => slugify(caseStudy.title) === (location.hash || "").replace("#", ""))
  const initialModal = (initialCaseStudy ? makeCaseStudyModalContent(initialCaseStudy) : null)
  const [modal, setModal] = useState(initialModal);

  const handleDisruptionClick = (i, j) => {
    const newOpenState = [...openState]

    const scrollY = window.scrollY

    newOpenState[i][j] = !openState[i][j]
    // also close description when closing disruption
    if(j === 0 && openState[i][0]) newOpenState[i][1] = false

    setOpenState(newOpenState)

    setTimeout(() => {
      window.scrollTo(0, scrollY)
    }, 250);
  }




  const handleCaseStudyClick = (e, caseStudy) => {
    e.stopPropagation();

    const modalContent = makeCaseStudyModalContent(caseStudy)

    setModal(modalContent)
    document.body.style.overflow = 'hidden';
    document.body.style.marginRight = '15px';

    navigate(`${location.pathname}#${slugify(caseStudy.title)}`)
  }

  const handlePageClick = (modalContent) => {
    setModal(modalContent)
    document.body.style.overflow = 'hidden';
    document.body.style.marginRight = '15px';
  }

  const closeModal = () => {
    setModal(null)
    document.body.style.overflow = 'unset';
    document.body.style.marginRight = '0px';

    window.history.pushState({}, '', '/');
  }




  return (
  <div className="wrapper">
    <Helmet
      title="Case Studies for Participatory Mobility - Urbanite"
      meta={[
        {
          name: "description",
          content: "How do disruptive innovations threaten or improve democratic governance of public policy and data with regard to civic participation, (social and environmental) sustainability, and shared values like openness, transparency, equality, and accountability?",
        }
      ]}
      link={[
        { rel: "shortcut icon", type: "image/svg+xml", href: `${favicon}` },
      ]}
    />
    <header id="project-header">
      <div id="logo-and-title">
        <img id="urbanite-logo" src={Logo} alt="Urbanite logo" />
        <div>
          <h2 id="project-title">{data.strapiHeader.title}</h2>
          <p id="project-description">{data.strapiHeader.subtitle}</p>
        </div>
      </div>
      <div id="project-links">
        <a href="https://forum.urbanite-project.eu/" target="_blank" rel="noreferrer">Urbanite Forum</a>
        <a href="https://urbanite-project.eu/" target="_blank" rel="noreferrer">Project Site</a>
      </div>
    </header>
    <main>
      <CircuitIn className="circuit-in" />
      <ChipIO className="chip-io" />
      <header id="site-header" className="square">
        <div>
          <h1>{ data.strapiSiteHeader.title }</h1>
          <div>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.strapiSiteHeader.introduction) }} />
          </div>
        </div>
      </header>
      <ChipIO className="chip-io" />
      <CircuitIn className="circuit-in" style={{ height: "60px" }} />
      <ChipIO className="chip-io" />
      <div id="introduction">
        { data.allStrapiIntroduction.nodes.map(introduction => {
          return <button key={introduction.title} className="page-button" onClick={ ()=> { handlePageClick({ title: introduction.title, body: <div className="page-content">
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(introduction.body)}} />
              { introduction.references && <div className="references">
                <h4>References</h4>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(introduction.references) }} />
              </div> }
            </div>
            }) }}>
            { introduction.title }
          </button>
        })}
      </div>
      <ChipIO className="chip-io" />
      <CircuitIn className="circuit-in" style={{ height: "30px" }} />
      
      <div id="site-body">
        <CircuitCornerTL className="circuit-corner-t-l" />
        <CircuitCornerTR className="circuit-corner-t-r" />
        <CircuitCornerBL className="circuit-corner-b-l" />
        <CircuitCornerBR className="circuit-corner-b-r" />

        <CircuitCenterT className="circuit-center-t" />
        <CircuitCenterB className="circuit-center-b" />

        <div className="circuit-h circuit-h-t-1">
          <CircuitHorizontal className="lines" />
          <Bus className="traffic traffic-h" style={{ left: `${randomBetween(5,95)}%` }} />
        </div>
        <div className="circuit-h circuit-h-t-2">
          <CircuitHorizontal className="lines" />
          <Bike className="traffic traffic-h" style={{ left: `${randomBetween(5,95)}%` }} />
        </div>
        
        <div className="circuit-h circuit-h-b-1">
          <CircuitHorizontal className="lines" />
          <Bike className="traffic traffic-h" style={{ left: `${randomBetween(5,95)}%` }} />
        </div>
        <div className="circuit-h circuit-h-b-2">
          <CircuitHorizontal className="lines" />
          <Bus className="traffic traffic-h" style={{ left: `${randomBetween(5,95)}%` }} />
        </div>

        <Bus className="traffic traffic-v circuit-v-r" style={{ top: `${randomBetween(5,95)}%` }} />
        <Bike className="bike traffic traffic-v circuit-v-l" style={{ top: `${randomBetween(5,45)}%` }} />
        <Bike className="bike traffic traffic-v circuit-v-l" style={{ top: `${randomBetween(55,95)}%` }} />


        <CircuitVertical className="circuit-v circuit-v-l" />
        <CircuitVertical className="circuit-v circuit-v-r" />

        <div className="content">
          <div className="column column-methods">
            <h2 className="theme-title">Disruptive Methods</h2>
            { disruptions.filter(disruption => disruption.theme === "Methods").map((disruption, i) => {
              return <div key={disruption.order} className="disruption">
                <h3 className="disruption-toggle" onClick={() => handleDisruptionClick(i, 0) }>{disruption.title}</h3>
                <div className={`disruption-content ${openState[i][0] ? "open" : "closed"}`}>
                  <div className={`disruption-description ${openState[i][1] ? "open" : "closed"}`} role="presentation" onClick={() => { if(!openState[i][1]) handleDisruptionClick(i, 1) }}>
                    <div>
                      <div>
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(disruption.description) }} />
                        { disruption.references && <div className="references">
                          <h4>References</h4>
                          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(disruption.references) }} />
                        </div> }
                      </div>
                    </div>
                    { !openState[i][1] && <button className="read-more" onClick={() => handleDisruptionClick(i, 1) }>read more</button>}
                  </div>
                  <div className="disruption-case-studies">
                    { disruption.caseStudies.map(caseStudy => {
                      return (
                        <div key={caseStudy.order} role="presentation" className="disruption-case-study square" onClick={(e)=>{handleCaseStudyClick(e, caseStudy)}}>
                          <div>
                            <h4>{ caseStudy.title }</h4>
                          </div>
                        </div>
                      )
                    }) }
                  </div>
                </div>
              </div>
            }) }
          </div>

          <div className="column column-technology">
            <h2 className="theme-title">Disruptive Technology</h2>
            { disruptions.filter(disruption => disruption.theme === "Technology").map((disruption, i) => {
              i = i + 4
              return <div key={disruption.order} className="disruption">
                <h3 className="disruption-toggle" onClick={() => handleDisruptionClick(i, 0) }>{disruption.title}</h3>
                <div className={`disruption-content ${openState[i][0] ? "open" : "closed"}`}>
                  <div className={`disruption-description ${openState[i][1] ? "open" : "closed"}`} role="presentation" onClick={() => { if(!openState[i][1]) handleDisruptionClick(i, 1) }}>
                    <div>
                      <div>
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(disruption.description) }} />
                        { disruption.references && <div className="references">
                          <h4>References</h4>
                          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(disruption.references) }} />
                        </div> }
                      </div>
                    </div>
                    { !openState[i][1] && <button className="read-more" onClick={() => handleDisruptionClick(i, 1) }>read more</button>}
                  </div>
                  <div className="disruption-case-studies">
                    { disruption.caseStudies.map(caseStudy => {
                      return (
                        <div key={caseStudy.order} className="disruption-case-study square" role="presentation" onClick={(e)=>{handleCaseStudyClick(e, caseStudy)}}>
                          <div>
                            <h4>{ caseStudy.title }</h4>
                          </div>
                        </div>
                      )
                    }) }
                  </div>
                </div>
              </div>
            }) }
          </div>

        </div>

      </div>
      <ChipIO className="chip-io" />
      <button className="page-button" id="conclusions-button" onClick={ ()=> { handlePageClick({ title: data.strapiConclusions.title, body: <div id="conclusions" className="page-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.strapiConclusions.body) }} /> }) }} >
              { data.strapiConclusions.title }
      </button>
    </main>

    <footer>
      <div className="footer-item">
        <Europe id="eu-flag" />
        <p>This project has received funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement # 870338</p>
      </div>
      <div className="footer-item">
        <a className="footer-link" href={ textOnlyPdf }>download text version (pdf)</a>
        <button className="footer-link" onClick={ ()=> { setModal({ title: data.strapiAbout.title, body: <div className="page-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.strapiAbout.body) }} /> }) }} >about this website</button>
        <button className="footer-link" onClick={ ()=> { setModal({ title: data.strapiReferences.title, body: <div className="references page-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.strapiReferences.body) }} /> }) }} >references</button>
      </div>
    </footer>

    { modal && <div id="modal" role="presentation" onClick={() => { closeModal() }}>
      <div className="content" role="presentation" onClick={event => { event.stopPropagation() }}>
        <h1>{ modal.title }</h1>
        <div className="button-close" role="presentation" onClick={()=> { closeModal() }}>×</div>
        { modal.body }
      </div>
    </div>}

  </div>
  )
}

export default IndexPage